import { Suspense, lazy } from 'react';
import { useAuth } from '~/context';
import { FullPageSpinner } from '~/components/lib/v3';

const AuthenticatedApp = lazy(() => import('~/components/AuthenticatedApp'));
const UnauthenticatedApp = lazy(
  () => import('~/components/UnauthenticatedApp')
);

export const AppRoutes = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};
