import React, { ReactNode, TextareaHTMLAttributes } from 'react';

export interface TextareaInputV3Props
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  state?: 'active' | 'success' | 'invalid';
  children?: ReactNode;
  className?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  label?: ReactNode;
  id: string;
  name?: string;
  resizable?: boolean;
  handleInputChange?: (event: any) => void;
  setSize?: string;
}

const getState = (state: TextareaInputV3Props['state']): string => {
  switch (state) {
    case 'success':
      return 'bg-white border rounded border-grey-800 focus:shadow-md-green focus:border-brand-primary hover:border-brand-primary';
    case 'active':
      return 'bg-white border rounded border-grey-800 focus:shadow-md-green focus:border-brand-primary  hover:border-brand-primary';
    case 'invalid':
      return 'bg-white border rounded border-danger p-3 text-danger focus:outline-none w-full font-primary text-xs';
    default:
      return 'bg-white border rounded border-grey-800 focus:shadow-md-green focus:border-brand-primary hover:border-brand-primary';
  }
};

const isResizable = (resize: TextareaInputV3Props['resizable']) => {
  switch (resize) {
    case true:
      return 'resize';
    case false:
      return 'resize-none';
  }
};

export const TextareaInputV3 = React.forwardRef<
  HTMLTextAreaElement,
  TextareaInputV3Props
>(
  (
    {
      state = 'active',
      required = false,
      value,
      placeholder,
      label,
      id,
      className,
      name,
      handleInputChange,
      resizable = true,
      setSize = 'w-full h-32', // Default size
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`flex flex-col ${className}`}>
        <div className="mb-2">
          <label
            className="text-[16px] font-normal text-grey-1600 w-max"
            htmlFor={id}
          >
            {label}
          </label>
        </div>
        <div className="relative">
          <textarea
            ref={ref}
            className={`p-3 text-sm bg-white focus:outline-none text-grey-1600 font-primary font-normal 
            placeholder:text-grey-1000 placeholder:text-sm
              ${isResizable(resizable)} ${getState(state)} ${setSize}`}
            required={required}
            value={value}
            placeholder={placeholder}
            id={id}
            name={name}
            onChange={handleInputChange}
            {...rest}
          />
        </div>
      </div>
    );
  }
);

TextareaInputV3.displayName = 'TextareaInputV3';
