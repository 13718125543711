import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import { GraphProvider } from './GraphContext';
import { PageProvider } from './PageContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

export const AppProviders: FC = ({ children }) => {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <GraphProvider>
            <PageProvider>{children}</PageProvider>
          </GraphProvider>
        </AuthProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
};
