import React, { HTMLAttributes, ReactNode, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { cva } from 'class-variance-authority';
import { Link } from '../../Link';

export type PasswordInputStates = 'active' | 'invalid';
export type PasswordInputComponents = 'input' | 'hint';

const input = cva(
  [
    'px-3',
    'py-2',
    'focus:outline-none',
    'focus:ring-0',
    'text-grey-1600',
    'w-full',
    'font-primary',
    'disabled:cursor-not-allowed',
    'text-sm',
    'font-normal',
    'border',
    'rounded-sm',
    'transition-colors',
    'ease-in',
    'disabled:bg-grey-200',
    'placeholder:text-grey-1000',
    'placeholder:text-sm',
    'placeholder:font-normal',
  ],
  {
    variants: {
      state: {
        invalid: ['bg-white', 'border-danger'],
        active: [
          'bg-white',
          'border-grey-800',
          'focus:border-brand-primary',
          'hover:border-brand-primary',
        ],
      },
    },
    defaultVariants: {
      state: 'active',
    },
  }
);

const hint = cva(['block', 'font-light', 'text-sm', 'text-center', 'mt-2', 'font-primary'], {
  variants: {
    state: {
      invalid: ['text-danger'],
      active: ['text-grey-1200'],
    },
  },
});

export interface PasswordInputProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  className?: string;
  children?: ReactNode;
  name?: string;
  state?: PasswordInputStates;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  placeholder?: string;
  label?: ReactNode;
  href?: string;
  forgetPasswordLabel?: string;
  helperText?: string;
  handleInputChange?: () => void;
}

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>(
  (
    {
      id,
      name,
      state = 'active',
      disabled = false,
      required = false,
      value,
      placeholder,
      label,
      href,
      forgetPasswordLabel = 'Forgot password',
      helperText,
      className,
      handleInputChange,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className={`flex flex-col text-left ${className}`}>
        <div className="flex flex-row justify-between">
          <label htmlFor={id} className="mb-2">
            {label}
          </label>

          { href && (
            <Link
              size='sm'
              variant='primary'
              href={ href }
              className='mb-2'
            >
              { forgetPasswordLabel }
            </Link>
          )}
        </div>

        <div className="relative">
          <input
            ref={ref}
            id={id}
            name={name}
            type={showPassword ? 'text' : 'password'}
            className={input({ state })}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            required={required}
            onChange={handleInputChange}
            {...rest}
          />

          {!disabled && (
            <div className="absolute top-3 right-4">
              {showPassword ? (
                <FiEye
                  className="text-grey-1200 cursor-pointer"
                  size={16}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FiEyeOff
                  className="text-grey-1200 cursor-pointer"
                  size={16}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </div>
          )}
        </div>

        {helperText && <div className={hint({})}>{helperText}</div>}
      </div>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';
