import { FC, ReactNode, HTMLAttributes } from 'react';
import { AnimatedLogo } from '~/components/lib/v3';

export interface FullPageSpinnerProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

export const FullPageSpinner: FC<FullPageSpinnerProps> = ({ ...props }) => {
  return (
    <div className="flex items-center justify-center min-h-screen" {...props}>
      <AnimatedLogo size="lg" />
    </div>
  );
};
