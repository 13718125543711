import { FC, HTMLAttributes } from 'react';
import { AnimatedLogoSizes } from './AnimatedLogo.styled';

export interface AnimatedLogoProps extends HTMLAttributes<SVGElement> {
  size?: AnimatedLogoSizes;
}

export const AnimatedLogo: FC<AnimatedLogoProps> = ({
  ...props
}) => {
  return (
    <div
      style={{
        animation: 'rotate 1.2s cubic-bezier(0.65, 0, 0.35, 1) infinite',
        transformOrigin: 'center', width: '60px', height: '60px',
      }}
    >
      <svg
        fill="none"
        viewBox="0 0 75 75"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        {...props}
        style={{
          animation: 'grow 1.2s cubic-bezier(0.65, 0, 0.35, 1) infinite',
          transformOrigin: 'center', width: '100%', height: '100%',
        }}
      >
        <g transform="matrix(0.3, 0, 0, 0.3, 0, 0)">
          <path d="M152.519 26.5862C152.519 47.1575 140.386 72.606 125.418 72.606C110.451 72.606 98.3178 47.1575 98.3178 26.5862C105.364 10.1217 110.451 0 125.418 0C140.386 0 146.828 10.6615 152.519 26.5862Z" fill="#2F2BB3"/>
          <path d="M152.519 26.9911C152.519 41.8978 140.386 53.9822 125.418 53.9822C110.451 53.9822 98.3178 41.8978 98.3178 26.9911C98.3178 12.0843 110.451 0 125.418 0C140.386 0 152.519 12.0843 152.519 26.9911Z" fill="#463EFF"/>
          <path d="M97.8981 223.414C97.8981 202.842 110.031 177.394 124.999 177.394C139.966 177.394 152.099 202.842 152.099 223.414C145.053 239.878 139.966 250 124.999 250C110.031 250 103.589 239.339 97.8981 223.414Z" fill="#2F2BB3"/>
          <path d="M97.8981 223.009C97.8981 208.102 110.031 196.018 124.999 196.018C139.966 196.018 152.099 208.102 152.099 223.009C152.099 237.916 139.966 250 124.999 250C110.031 250 97.8981 237.916 97.8981 223.009Z" fill="#463EFF"/>
          <path d="M223.306 152.496C202.651 152.496 177.099 140.411 177.099 125.505C177.099 110.598 202.651 98.5135 223.306 98.5135C239.837 105.531 250 110.598 250 125.505C250 140.411 239.295 146.828 223.306 152.496Z" fill="#2F2BB3"/>
          <path d="M222.899 152.496C207.932 152.496 195.799 140.411 195.799 125.505C195.799 110.598 207.932 98.5135 222.899 98.5135C237.867 98.5135 250 110.598 250 125.505C250 140.411 237.867 152.496 222.899 152.496Z" fill="#463EFF"/>
          <path d="M26.6941 98.5144C47.3489 98.5144 72.9006 110.599 72.9006 125.505C72.9006 140.412 47.3489 152.497 26.6941 152.497C10.1627 145.479 -1.96272e-06 140.412 0 125.505C1.96272e-06 110.599 10.7047 104.183 26.6941 98.5144Z" fill="#2F2BB3"/>
          <path d="M27.1006 98.5144C42.0679 98.5144 54.2012 110.599 54.2012 125.505C54.2012 140.412 42.0678 152.497 27.1006 152.497C12.1333 152.497 -1.96272e-06 140.412 0 125.505C1.96272e-06 110.599 12.1334 98.5144 27.1006 98.5144Z" fill="#463EFF"/>
          <path d="M213.671 75.3577C199.066 89.9039 172.419 99.3537 161.835 88.8131C151.252 78.2724 160.74 51.7327 175.345 37.1866C192.017 30.5066 202.8 26.9322 213.384 37.4729C223.967 48.0135 220.953 60.0893 213.671 75.3577Z" fill="#2F2BB3"/>
          <path d="M213.384 75.644C202.8 86.1847 185.641 86.1847 175.058 75.644C164.474 65.1033 164.474 48.0135 175.058 37.4729C185.641 26.9322 202.8 26.9322 213.384 37.4729C223.967 48.0135 223.967 65.1033 213.384 75.644Z" fill="#463EFF"/>
          <path d="M36.3198 175.651C50.925 161.105 77.5723 151.655 88.1558 162.196C98.7392 172.737 89.251 199.276 74.6458 213.823C57.974 220.503 47.1907 224.077 36.6072 213.536C26.0238 202.996 29.0379 190.92 36.3198 175.651Z" fill="#2F2BB3"/>
          <path d="M36.6072 175.365C47.1907 164.825 64.3498 164.825 74.9333 175.365C85.5167 185.906 85.5167 202.996 74.9333 213.536C64.3498 224.077 47.1907 224.077 36.6072 213.536C26.0238 202.996 26.0238 185.906 36.6072 175.365Z" fill="#463EFF"/>
          <path d="M175.347 213.823C160.742 199.277 151.254 172.737 161.837 162.197C172.421 151.656 199.068 161.106 213.673 175.652C220.38 192.256 223.969 202.996 213.386 213.537C202.803 224.078 190.678 221.076 175.347 213.823Z" fill="#2F2BB3"/>
          <path d="M175.06 213.537C164.476 202.996 164.476 185.906 175.06 175.366C185.643 164.825 202.803 164.825 213.386 175.366C223.969 185.906 223.969 202.996 213.386 213.537C202.803 224.078 185.643 224.078 175.06 213.537Z" fill="#463EFF"/>
          <path d="M74.6462 37.1875C89.2513 51.7337 98.7395 78.2733 88.1561 88.814C77.5727 99.3547 50.9253 89.9048 36.3201 75.3587C29.6131 58.7542 26.0241 48.0145 36.6076 37.4738C47.191 26.9331 59.3158 29.935 74.6462 37.1875Z" fill="#2F2BB3"/>
          <path d="M74.9336 37.4738C85.5171 48.0145 85.5171 65.1043 74.9336 75.645C64.3502 86.1856 47.191 86.1856 36.6076 75.645C26.0241 65.1043 26.0241 48.0145 36.6076 37.4738C47.191 26.9331 64.3502 26.9331 74.9336 37.4738Z" fill="#463EFF"/>
        </g>

        <style>
          {`
            @keyframes grow {
              15%, 85% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.2);
              }
            }
            
          `}
        </style>
      </svg>

      <style>
        {`
          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }          
        `}
      </style>
    </div>
  );
};
