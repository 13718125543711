import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/utils/shadcn';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        primary:
          'font-medium border border-border-black hover:border-border-dark bg-btn-bg-primary text-btn-txt-primary hover:bg-btn-bg-primary-hover',
        secondary:
          'font-medium border border-border-light hover:border-border-main bg-btn-bg-secondary text-btn-txt-secondary hover:bg-btn-bg-secondary-hover',
        outline:
          'font-medium border border-border-btn-outline text-btn-txt-outline bg-btn-bg-outline hover:bg-btn-bg-outline-hover',
        ai: 'font-medium border border-border-btn-ai text-btn-txt-ai bg-btn-bg-ai hover:bg-btn-bg-ai-hover',
        input:
          'font-regular border border-border-input text-btn-txt-outline bg-btn-bg-outline hover:bg-btn-bg-outline-hover',
        ghost: 'text-txt-secondary hover:text-txt-primary',
      },
      size: {
        sm: 'px-3 py-1 gap-1.5 rounded-sm text-xs',
        md: 'px-4 py-2 gap-1.5 rounded-sm text-sm h-10',
        lg: 'px-6 py-2.5 gap-3 rounded-sm text-base',
        icon: 'p-2 min-w-10 items-center justify-center',
        zero: 'p-0',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
