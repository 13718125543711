import { FC, useMemo } from 'react';
import { Provider, createClient, fetchExchange } from 'urql';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useAuth } from './AuthContext';
import { cacheExchange } from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';

const ENV = import.meta.env;

const createUrqlClient = ({
  getSession,
}: {
  getSession: () => Promise<CognitoUserSession | null>;
}) => {
  return createClient({
    url: ENV.VITE_GRAPHQL_HOST ?? 'http://localhost:4000/graphql',
    exchanges: [
      devtoolsExchange,
      cacheExchange({
        updates: {
          Mutation: {
            rescheduleChat(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getChats')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createOneOnOnes(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getChats' ||
                    field.fieldName === 'getChatSettings'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            cancelChat(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getChats')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateChatSettings(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getChats' ||
                    field.fieldName === 'getChatSettings'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateOneOnOneSettings(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getChatSettings')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            removeOneOnOneSetting(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getChatSettings')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            disconnectSlackWorkspace(_result, _args, cache, _info) {
              // TODO: Update isConnectedWithSlack field
              //       by writing to User fragment
              //       instead of invalidating cache.
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertSlackWorkflows(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getSlackWorkflows')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateGlobalLocation(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getUserSelectedLocation')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertEventVote(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllEvents' ||
                    field.fieldName === 'getEvent'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createEvent(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getAllEvents' ||
                    field.fieldName === 'getEvent'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateEvent(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllEvents' ||
                    field.fieldName === 'getEvent'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteEvent(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllEvents' ||
                    field.fieldName === 'getEvent'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertRSVPStatus(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllEvents' ||
                    field.fieldName == 'getEvent'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertRecommendationLog(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'checkScheduleRecommendationAccepted'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateUser(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createTenant(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                // .filter((field) => field.fieldName == 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateTenant(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTenant')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateTenantPlan(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTenant')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createCommunity(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllCommunities' ||
                    field.fieldName == 'getCommunity'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateCommunity(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllCommunities' ||
                    field.fieldName == 'getCommunity'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteCommunity(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllCommunities' ||
                    field.fieldName == 'getCommunity'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            joinCommunity(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllCommunities' ||
                    field.fieldName == 'getCommunity'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            leaveCommunity(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllCommunities' ||
                    field.fieldName == 'getCommunity'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            updateLocation(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'listLocations')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createLocation(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'listLocations')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteLocation(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'listLocations')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createRoom(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'listAllRooms' ||
                    field.fieldName === 'listAllRoomBookings'
                )

                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateRoom(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'listAllRooms' ||
                    field.fieldName === 'listAllRoomBookings'
                )

                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteRoom(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'listAllRooms' ||
                    field.fieldName === 'listAllRoomBookings'
                )

                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createRoomBooking(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'listAllRoomBookings')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateRoomBooking(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'listAllRoomBookings')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteRoomBooking(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'listAllRoomBookings')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createWorkflow(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllWorkflows' ||
                    field.fieldName == 'getWorkflow'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteWorkflow(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllWorkflows' ||
                    field.fieldName == 'getWorkflow'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateWorkflow(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllWorkflows' ||
                    field.fieldName == 'getWorkflow'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            createMessages(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllWorkflows' ||
                    field.fieldName == 'getWorkflow' ||
                    field.fieldName == 'getAllMessages'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            updateMessageInQueue(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getAllMessages')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteMultipleMessages(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllWorkflows' ||
                    field.fieldName == 'getWorkflow' ||
                    field.fieldName == 'getAllMessages'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deleteMessageFromQueue(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName == 'getAllMessages')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            buildForm(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getFormsDetails' ||
                    field.fieldName === 'getAllFormQuestionsInForm' ||
                    field.fieldName === 'getForm' ||
                    field.fieldName == 'getFormQuestionInsights'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            createFormBatch(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getFormBatchesDetails' ||
                    field.fieldName == 'getFormBatch' ||
                    field.fieldName == 'getFormsToComplete' ||
                    field.fieldName == 'getFormResponsesDetailsInForm'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            updateFormBatch(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getFormBatchesDetails' ||
                    field.fieldName == 'getFormBatch' ||
                    field.fieldName == 'getFormsToComplete' ||
                    field.fieldName == 'getFormResponsesDetailsInForm'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            archiveFormBatch(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getFormBatchesDetails' ||
                    field.fieldName == 'getFormBatch' ||
                    field.fieldName == 'getFormsToComplete' ||
                    field.fieldName == 'getFormResponsesDetailsInForm' ||
                    field.fieldName == 'getFormQuestionInsights'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            updateFormBatchUserOpenedStatus(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getFormBatchesDetails' ||
                    field.fieldName == 'getFormBatch' ||
                    field.fieldName == 'getFormsToComplete'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },

            submitFormResponse(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName == 'getAllFormResponsesInForm' ||
                    field.fieldName == 'getFormsToComplete' ||
                    field.fieldName == 'getFormResponsesToSubmit' ||
                    field.fieldName == 'getFormQuestionInsights' ||
                    field.fieldName == 'getFormResponsesDetailsInForm'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            unfollowUser(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            followUser(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            removeUserTeam(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            addTeamUser(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getUser' ||
                    field.fieldName === 'getTeam' ||
                    field.fieldName === 'getAllTeams'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            upsertStatus(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getStatuses')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateTeam(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getTeam' ||
                    field.fieldName === 'getAllTeams'
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createTeam(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getTeam' ||
                    field.fieldName === 'getAllTeams'
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            removeTeam(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) =>
                    field.fieldName === 'getTeam' ||
                    field.fieldName === 'getAllTeams'
                )
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            storeGoogleCalAuthTokens(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            disconnectGoogleCalUserAuth(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getUser')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            createTimeBlock(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTimeBlocks')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            updateTimeBlock(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTimeBlocks')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            deleteTimeBlock(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTimeBlocks')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            renewPendingCancelledSubscription(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTenant')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },
            readAllNotifications(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getNotifications')
                .forEach((field) => {
                  cache.invalidate('Query', field.fieldKey);
                });
            },

            updateNotificationSettings(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter(
                  (field) => field.fieldName == 'getNotificationsSettings'
                )
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
            deactivateUser(_result, _args, cache, _info) {
              cache
                .inspectFields('Query')
                .filter((field) => field.fieldName === 'getTenantUsers')
                .forEach((field) => cache.invalidate('Query', field.fieldKey));
            },
          },
        },
        keys: {
          GetTeamInsightOutput: () => null,
          InOfficeFrequency: () => null,
          GetTeamOutput: (data) => data.team_uuid as string,
          GetTeamUsersOutput: (data) => data.team_uuid as string,
          Message: () => null,
          Notification: (data) => data.notification_uuid as string,
          Tenant: (data) => data.tenant_uuid as string,
          Locations: (data) => data.location_uuid as string,
          TenantRole: () => null,
          TenantUserOutput: () => null,
          User: (data) => data.user_uuid as string,
          MemberInvitesOutput: () => null,
          CapacityUtilization: () => null,
          GetPastFourWeeksOutput: () => null,
          StatusDetails: () => null,
          EventGuest: () => null,
          EventDetailedOutput: (data) => data.event_uuid as string,
          EventVote: () => null,
          PollOption: () => null,
          EventListOutput: () => null,
          EventUser: (data) => data.user_uuid as string,
          EventDateRecommendationOutput: () => null,
          EventRecommendation: () => null,
          StatusRate: () => null,
          GetReportOutput: () => null,
          MemberInviteOutput: () => null,
          UserOutput: (data) => data.user_uuid as string,
          Team: (data) => data.team_uuid as string,
          LocationsOutput: (data) => data.location_uuid as string,
          OfficeRecommendationsOutput: () => null,
        },
      }),
      authExchange(async ({ appendHeaders }) => {
        const session = await getSession();
        const accessToken = session?.getAccessToken().getJwtToken();

        return {
          addAuthToOperation(operation) {
            if (!accessToken) {
              return operation;
            }

            return appendHeaders(operation, {
              Authorization: `Bearer ${accessToken}`,
            });
          },
          // willAuthError: ({ authState }: { authState: AuthState | null }) => !authState || !authState.token,
          didAuthError(error) {
            return error.graphQLErrors.some(
              (e) =>
                e.extensions?.code === 401 || e.message.includes('authorized')
            );
          },
          async refreshAuth() {
            // Refresh logic is handled by Amplify
            return;
          },
        };
      }),
      retryExchange({}),
      fetchExchange,
    ],
  });
};

const createUnauthorizedUrqlClient = () => {
  return createClient({
    url: ENV.VITE_GRAPHQL_HOST ?? 'http://localhost:4000',
    exchanges: [devtoolsExchange, fetchExchange],
  });
};

export const GraphProvider: FC = ({ children }) => {
  const { user, getSession } = useAuth();

  const urqlClient = useMemo(() => {
    if (!user) {
      return createUnauthorizedUrqlClient();
    }
    return createUrqlClient({ getSession });
  }, [user, getSession]);

  return <Provider value={urqlClient}>{children}</Provider>;
};
