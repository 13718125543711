import tw from 'twin.macro';
import styled from 'styled-components';

export type SpinnerSizes = 'sm' | 'lg';

const getSpinnerSize = ({ size }: { size: SpinnerSizes }) => {
  switch (size) {
    case 'lg':
      return tw`w-14 h-14`;

    case 'sm':
    default:
      return tw`w-6 h-6`;
  }
};

export const StyledSpinner = styled.svg<{ size: SpinnerSizes }>`
  ${() => tw`text-txt-primary animate-spin`}
  ${({ size }) => getSpinnerSize({ size })}
`;
