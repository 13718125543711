import { FC, HTMLAttributes, ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';

const paragraphVariants = cva(['leading-[150%]'], {
  variants: {
    size: {
      xxs: ['text-[10px]'],
      xs: ['text-xs'],
      sm: ['text-sm'],
      md: ['text-base'],
      lg: ['text-lg'],
    },
    weight: {
      regular: ['font-normal'],
      medium: ['font-medium'],
      semibold: ['font-semibold'],
    },
  },
  defaultVariants: {
    size: 'md',
    weight: 'regular',
  },
});

export interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
  key?: React.Key;
  size?: VariantProps<typeof paragraphVariants>['size'];
  weight?: VariantProps<typeof paragraphVariants>['weight'];
  color?: string;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const Paragraph: FC<ParagraphProps> = ({
  size = 'md',
  weight = 'regular',
  color = 'text-grey-1600',
  className = '',
  children,
  onClick,
  ...props
}) => {
  return (
    <p
      className={
        color +
        ' ' +
        paragraphVariants({
          size,
          weight,
          className,
        })
      }
      onClick={onClick}
      {...props}
    >
      {children}
    </p>
  );
};
