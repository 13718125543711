import { createContext, FC, useContext, useState } from 'react';

interface IPageContext {
  selectedLocationId: string;
}

interface IPageActionsContext {
  setSelectedLocationId: (id: string) => void;
}

const PageContext = createContext<IPageContext>({
  selectedLocationId: '',
});

const PageActionsContext = createContext<IPageActionsContext>({
  setSelectedLocationId: () => null,
});

export const PageProvider: FC = ({ children }) => {
  const [selectedLocationId, setSelectedLocationId] = useState('');

  return (
    <PageContext.Provider value={{ selectedLocationId }}>
      <PageActionsContext.Provider value={{ setSelectedLocationId }}>
        {children}
      </PageActionsContext.Provider>
    </PageContext.Provider>
  );
};

export const usePage = (): [IPageContext, IPageActionsContext] => {
  const page = useContext(PageContext);
  const pageActions = useContext(PageActionsContext);

  return [page, pageActions];
};
