import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { AppProviders } from '~/context';
import { AppRoutes } from '~/routes';
import './index.css';
import 'rc-tooltip/assets/bootstrap.css';

const history = createBrowserHistory();
const appElement = document.getElementById('app');

const App = () => {
  return (
    <StrictMode>
      <AppProviders>
        <AppRoutes />
      </AppProviders>
    </StrictMode>
  );
};

// This is required for client side routing in S3
if (appElement) {
  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  createRoot(appElement).render(<App />);
}
