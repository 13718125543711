import day from 'dayjs';
import Calendar from 'dayjs/plugin/calendar';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import Weekday from 'dayjs/plugin/weekday';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import UTC from 'dayjs/plugin/utc';
import WeekOfYear from 'dayjs/plugin/weekOfYear';

day.extend(Calendar);
day.extend(UpdateLocale);
day.extend(Weekday);
day.extend(isBetween);
day.extend(isSameOrAfter);
day.extend(UTC);
day.extend(WeekOfYear);

day.updateLocale('en', { weekStart: 1 });

export const dayjs = day;
