import { cva } from 'class-variance-authority';
import React, { HTMLAttributes, ReactNode } from 'react';
import { Paragraph } from '../../Text';

export type TextInputStates = 'active' | 'invalid' | 'success';

const text = cva(['block', 'mt-2'], {
  variants: {
    state: {
      active: ['text-grey-1200'],
      invalid: ['text-danger'],
      success: ['text-grey-1200'],
    },
  },
});

const input = cva(
  [
    'px-3',
    'py-2',
    'h-10',
    'focus:outline-none',
    'focus:ring-0',
    'text-grey-1600',
    'w-full',
    'font-primary',
    'disabled:cursor-not-allowed',
    'text-sm',
    'font-normal',
    'border',
    'transition-colors',
    'ease-in',
    'disabled:bg-grey-200',
    'placeholder:text-grey-1000',
    'placeholder:text-sm',
    'placeholder:font-normal',
  ],
  {
    variants: {
      state: {
        // TODO: Remove this variant since it is not used and is identical to active
        success: [
          'bg-white',
          'rounded-sm',
          'border-grey-800',
          'focus:border-brand-primary',
          'hover:border-brand-primary',
        ],
        invalid: ['bg-white', 'rounded', 'border-danger'],
        active: [
          'bg-white',
          'rounded',
          'border-grey-800',
          'focus:border-brand-primary',
          'hover:border-brand-primary',
        ],
      },
    },
    defaultVariants: {
      state: 'active',
    },
  }
);

export interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  state?: TextInputStates;
  disabled?: boolean;
  required?: boolean;
  value?: string | number;
  placeholder?: string;
  label?: ReactNode;
  id: string;
  helperText?: string;
  name?: string;
  type?: 'text' | 'email';
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      state = 'active',
      disabled = false,
      required = false,
      value,
      placeholder,
      label,
      id,
      helperText,
      className,
      name,
      handleInputChange,
      type = 'text',
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`flex flex-col text-left ${className}`}>
        {label && (
          <div className="flex flex-row justify-between mb-2">
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className="relative">
          <input
            ref={ref}
            className={input({ state })}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            id={id}
            name={name}
            type={type}
            required={required}
            onChange={handleInputChange}
            {...rest}
          />

          {helperText && state === 'invalid' && (
            <Paragraph size="sm" weight="regular" color={text({ state })}>
              {helperText}
            </Paragraph>
          )}
        </div>
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
