import { useState, useEffect, createContext, useContext } from 'react';
import { Amplify, Auth, Hub } from 'aws-amplify';
import {
  CognitoUser,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth';
import { type HubCallback } from '@aws-amplify/core';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { useLocation, useNavigate } from 'react-router';

const ENV = import.meta.env;

const authOptions = {
  aws_cognito_region: ENV.VITE_COGNITO_REGION,
  aws_user_pools_id: ENV.VITE_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: ENV.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: 'token',
    domain: ENV.VITE_COGNITO_AUTH_DOMAIN,
  },
};

Amplify.configure(authOptions);

const USER_NAME_PARAM = 'u';

export interface IUser {
  // username: string;
  email: string;
  preferred_name: string;
  tenant_uuid: string;
  role: string;
  user_uuid: string;
  image?: string;
  timezone?: string;
  access_token: CognitoAccessToken;
  id_token: CognitoIdToken;
}

export interface ICognitoUser {
  username: string;
  password: string;
  attributes: {
    email: string;
  };
  clientMetadata: {
    user_id: string;
  };
}

interface IAuthContext {
  isLoading: boolean;
  user: IUser | null;
  signIn(username: string, password: string): Promise<CognitoUser>;
  signOut(): Promise<any>;
  forgotPassword({ username }: { username: string }): Promise<any>;
  forgotPasswordSubmit({
    username,
    code,
    newPassword,
  }: {
    username: string;
    code: string;
    newPassword: string;
  }): Promise<any>;
  confirmSignUp(
    username: string,
    confirmationCode: string,
    tenantCode?: string
  ): Promise<any>;
  changePassword({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }): Promise<any>;
  signInWithGoogle(): Promise<any>;
  getSession(): Promise<CognitoUserSession | null>;
  isOnboarded: boolean;
}

const signIn = (username: string, password: string): Promise<CognitoUser> =>
  Auth.signIn(username, password).catch((e) => {
    throw e;
  });

const signOut = (): Promise<any> => Auth.signOut();

const changePassword = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}): Promise<any> => {
  return Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, oldPassword, newPassword))
    .catch((e) => {
      throw e;
    });
};

const confirmSignUp = (
  username: string,
  confirmationCode: string,
  tenantCode?: string
): Promise<any> =>
  Auth.confirmSignUp(username, confirmationCode, {
    clientMetadata: {
      tenant_code: tenantCode || '',
    },
  }).then(
    () =>
      (window.document.location = tenantCode
        ? `/sign-in?tc=${tenantCode}`
        : '/sign-in')
  );

const forgotPassword = ({ username }: { username: string }): Promise<any> => {
  return Auth.forgotPassword(username).catch((e) => {
    throw e;
  });
};

const forgotPasswordSubmit = ({
  username,
  code,
  newPassword,
}: {
  username: string;
  code: string;
  newPassword: string;
}): Promise<any> => {
  return Auth.forgotPasswordSubmit(username, code, newPassword).catch((e) => {
    throw e;
  });
};

const getSession = (): Promise<CognitoUserSession | null> =>
  Auth.currentSession();

const signInWithGoogle = async () => {
  return Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
  });
};

const useCognito = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState(false);

  const loadUserProfile = async () => {
    let session;
    try {
      setIsLoading(true);
      session = await getSession();

      let user = null;
      if (session && session.isValid()) {
        user = await Auth.currentUserInfo();
      }

      try {
        const appSyncHost = `${ENV.VITE_GRAPHQL_HOST}`.split('/')[2];

        const accessToken = session?.getAccessToken();
        const idToken = session?.getIdToken();
        const userId = idToken?.payload['custom:user_id'];

        const response = await fetch(`${ENV.VITE_GRAPHQL_HOST}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken?.getJwtToken()}`,
            IDToken: `Bearer ${idToken?.getJwtToken()}`,
            host: appSyncHost, // the middle bits of the URL
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            variables: {
              locale: 'en-US',
              user_uuid: userId,
            },
            query: `
                query GetUser($user_uuid: String!) {\n
                  getUser(user_uuid: $user_uuid) {\n
                    is_onboarded\n
                    preferred_name\n
                    tenant_uuid \n
                    role\n
                    user_uuid\n
                    image\n
                    timezone\n
                     __typename\n  }\n}
          `,
          }),
        });
        const json = await response.json();

        if (session && session.isValid() && user) {
          setUser({
            email: user.attributes.email,
            // cognito id, do not use as user_uuid
            // username: user.username,
            access_token: session.getAccessToken(),
            id_token: session.getIdToken(),
            preferred_name: json.data.getUser.preferred_name,
            role: json.data.getUser.role,
            tenant_uuid: json.data.getUser.tenant_uuid,
            user_uuid: userId,
            image: json.data.getUser.image,
            timezone: json.data.getUser.timezone,
          });
        }

        setIsOnboarded(json.data.getUser.is_onboarded || false);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      return;
    }
  };

  const authListener: HubCallback = ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
        loadUserProfile();
        break;
      case 'signUp': {
        const params = new URLSearchParams(location.search);

        params.set(USER_NAME_PARAM, encodeURIComponent(data.user.username));

        navigate({
          pathname: '/verify-email',
          search: `?${params.toString()}`,
        });
        break;
      }
      case 'signOut':
        setUser(null);
        break;
    }
  };

  useEffect(() => {
    loadUserProfile();
  }, []);

  useEffect(() => {
    Hub.listen('auth', authListener);
    return () => Hub.remove('auth', authListener);
  }, []);

  return {
    isLoading,
    user,
    signInWithGoogle,
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    confirmSignUp,
    getSession,
    isOnboarded,
  };
};

const AuthContext = createContext<IAuthContext>({
  isLoading: false,
  user: null,
  signInWithGoogle,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
  signOut,
  changePassword,
  confirmSignUp,
  getSession,
  isOnboarded: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useCognito();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
