import tw from 'twin.macro';
import styled from 'styled-components';

export const StyledErrorMessage = styled.div`
  ${tw`inline-block text-danger`}
`;

export const StyledErrorInfo = styled.pre`
  ${tw`inline-block m-0 -mb-5`}
`;
