import { Dayjs } from 'dayjs';
import { DurationEnum, StatusEnum } from '~/generated/graphql';

export type UserStatus = {
  user_uuid: string;
  status?: StatusEnum;
  duration?: DurationEnum;
  date: Dayjs;
  preferred_name?: string;
  checked_in?: boolean;
};

export type DaySchedule = {
  date: Dayjs;
  statuses: UserStatus[];
  recommendations: string[];
  events: string[];
};

/**
 * Statuses which are displayed on the UI.
 */
export const displayStatuses: StatusEnum[] = [
  StatusEnum['OFFICE'],
  StatusEnum['NOT_WORKING'],
  StatusEnum['REMOTE'],
];

/**
 * Returns the corresponding label for the given status enum.
 */
export const getStatusLabel = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.REMOTE:
      return 'Remote';

    case StatusEnum.OFFICE:
      return 'In office';

    case StatusEnum.NOT_WORKING:
      return 'Not working';

    case StatusEnum.NO_STATUS:
      return 'No status selected';
  }
};

/**
 * Filters out users who don't have the status `statusType` in the array
 * `statuses`.
 * @param statuses Contains the statuses of users on a specific day.
 */
export const getUsersOfStatus = (
  statusType: StatusEnum,
  statuses: DaySchedule['statuses'] = []
) => {
  return statuses.filter((s) => s.status === statusType);
};
