import {
  ReactNode,
  useState,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
} from 'react';

export interface ToggleInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  onToggle?: (value: boolean) => void;
  defaultChecked?: boolean;
  checked?: boolean;
  childrenPosition?: 'left' | 'right';
  colorTransition?: boolean;
}

export const ToggleInput = forwardRef<HTMLInputElement, ToggleInputProps>(
  (
    {
      children,
      onToggle,
      defaultChecked = false,
      checked,
      className = '',
      childrenPosition = 'right',
      ...props
    },
    ref
  ) => {
    const [isToggled, setIsToggled] = useState(checked ?? defaultChecked);

    useEffect(() => {
      if (checked !== null && checked !== undefined) {
        setIsToggled(checked);
      }
    }, [checked]);

    useEffect(() => {
      setIsToggled(defaultChecked);
    }, [defaultChecked]);

    return (
      <div className={`flex items-center space-x-2 ${className}`}>
        {childrenPosition === 'left' && (
          <span className="font-primary">{children}</span>
        )}
        <label className="relative inline-block w-9 h-5">
          <input
            type="checkbox"
            className="hidden peer"
            id="ToggleSwitchInput"
            ref={ref}
            checked={isToggled}
            onChange={() => {
              setIsToggled(!isToggled);
              onToggle && onToggle(!isToggled);
            }}
            {...props}
          />
          <span
            className={`
              bg-grey-800 absolute rounded-full top-0 bottom-0 left-0 right-0 ${ props.disabled ? 'cursor-not-allowed' : 'cursor-pointer' }
              before:bg-white before:absolute before:right-[18px] before:top-0.5 before:w-4 before:h-4 before:rounded-full
              peer-checked:bg-brand-primary peer-checked:before:translate-x-4 before:transition-transform before:duration-300
           `}
          />
        </label>
        {childrenPosition === 'right' && (
          <span className="font-primary">{defaultChecked && children}</span>
        )}
      </div>
    );
  }
);

ToggleInput.displayName = 'ToggleInput';
