import { FC, HTMLAttributes, ReactNode } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { type VariantProps, cva } from 'class-variance-authority';

const link = cva(
  ['flex', 'leading-[150%]', 'hover:underline', 'hover:underline-offset-4'],
  {
    variants: {
      variant: {
        primary: ['text-txt-primary', 'hover:text-brand-secondary'],
        secondary: [
          'text-grey-1600',
          'underline',
          'underline-offset-4',
          'hover:text-txt-primary',
        ],
        tertiary: ['text-grey-1000', 'hover:text-grey-1600'],
        disabled: ['text-grey-800', 'cursor-not-allowed'],
      },
      size: {
        sm: ['text-sm', 'font-medium'],
        lg: ['text-base', 'font-medium'],
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'lg',
    },
  }
);

type LinkTarget = '_blank' | '_self' | '_parent' | '_top' | 'framename';
export interface LinkProps extends HTMLAttributes<HTMLDivElement> {
  href: string;
  ariaLabel?: string;
  children?: ReactNode;
  target?: LinkTarget;
  className?: string;
  variant?: VariantProps<typeof link>['variant'];
  size?: VariantProps<typeof link>['size'];
  onClick?: () => void;
  isButton?: boolean;
}

const isAbsoluteLink = (href: string) => {
  return href.slice(0, 4) === 'http';
};

export const Link: FC<LinkProps> = ({
  children,
  href,
  variant = 'primary',
  size = 'lg',
  target,
  className = '',
  ariaLabel,
  onClick,
  isButton = false,
}) => {
  return isAbsoluteLink(href) ? (
    <a
      href={href}
      className={link({ variant, size, className })}
      target={target}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      {children}
    </a>
  ) : !isButton ? (
    <ReactLink
      to={href}
      className={link({ variant, size, className })}
      onClick={onClick}
    >
      {children}
    </ReactLink>
  ) : (
    <button className={link({ variant, size, className })} onClick={onClick}>
      {children}
    </button>
  );
};
