import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledBlurBackground = styled.div`
  ${tw`fixed inset-0 z-40 w-screen h-screen`}

  background-color: rgba(0, 0, 0, 0.5);
`;

export const StyledModal = styled.article`
  ${tw`fixed inset-0 p-6 m-auto bg-white border rounded-md border-border-main overflow-y-auto`}

  width: fit-content;
  height: fit-content;

  max-width: 90vw;
  max-height: 90vh;

  z-index: 999;
`;

export const MobileStyledModal = styled.article`
  ${tw`fixed bottom-0 left-0 flex flex-col w-full px-4 py-5 overflow-y-auto bg-white border rounded-lg lg:p-8 lg:overflow-visible border-grey-800`};

  max-height: 95%;

  @media (min-width: 1024px) {
    width: 768px;
    height: max-content;
    max-height: none;
  }

  z-index: 999;
`;
