import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "src/utils/shadcn"

const navLinkVariants = cva(
  "inline-flex items-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        active: "justify-left font-semibold text-txt-purple hover:text-txt-purple",
        inactive: "justify-left font-medium text-txt-primary hover:text-txt-purple",
        disabled: "justify-left font-medium text-txt-secondary cursor-not-allowed",
      },
      size: {
        sm: "gap-3 text-sm",
        lg: "gap-4 text-lg",
      },
    },
    defaultVariants: {
      variant: "active",
      size: "sm",
    },
  }
)

export interface NavLinkProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof navLinkVariants> {
  asChild?: boolean
}

const NavLink = React.forwardRef<HTMLButtonElement, NavLinkProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(navLinkVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
NavLink.displayName = "NavLink"

export { NavLink, navLinkVariants }
