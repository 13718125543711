import tw, { TwStyle } from 'twin.macro';
import styled from 'styled-components';

export type StyleMap =
  | {
      [key in SecondaryColor]: TwStyle;
    }
  | { [key in Size]: TwStyle };

export type SecondaryColor =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';
export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const sizeStyles: StyleMap = {
  xxs: tw`w-4 h-4 text-[8px] p-1`,
  xs: tw`w-7 h-7 text-xs border border-white mx-[-0.15rem]`,
  sm: tw`w-8 h-8 text-xs font-medium`,
  md: tw`w-10 h-10`,
  lg: tw`w-12 h-12`,
  xl: tw`w-[72px] h-[72px] font-semibold text-xl`,
  xxl: tw`w-28 h-28 font-semibold text-xl`,
};

const secondaryColorStyles: StyleMap = {
  a: tw`bg-[#9792E3]`,
  b: tw`bg-[#9792E3]`,
  c: tw`bg-[#9792E3]`,
  d: tw`bg-[#FF5E5B]`,
  e: tw`bg-[#C6A15B]`,
  f: tw`bg-[#C6A15B]`,
  g: tw`bg-[#FF5E5B]`,
  h: tw`bg-[#FF5E5B]`,
  i: tw`bg-[#00CECB]`,
  j: tw`bg-[#00CECB]`,
  k: tw`bg-[#FF70A6]`,
  l: tw`bg-[#FF70A6]`,
  m: tw`bg-[#32965D]`,
  n: tw`bg-[#32965D]`,
  o: tw`bg-[#BCA371]`,
  p: tw`bg-[#BCA371]`,
  q: tw`bg-[#B288C0]`,
  r: tw`bg-[#B288C0]`,
  s: tw`bg-[#B288C0]`,
  t: tw`bg-[#FF70A6]`,
  u: tw`bg-[#BB8A89]`,
  v: tw`bg-[#BB8A89]`,
  w: tw`bg-[#FA9F42]`,
  x: tw`bg-[#FA9F42]`,
  y: tw`bg-[#00CECB]`,
  z: tw`bg-[#9792E3]`,
};

export const StyledAvatar = styled.div<{
  imageUrl?: string;
  themeColor?: SecondaryColor;
  size?: Size;
}>`
  ${tw`flex items-center justify-center font-normal rounded-full font-primary text-white ring-white`};
  ${({ size }) => size && sizeStyles[size]};
  ${({ themeColor, imageUrl }) =>
    themeColor && !imageUrl ? secondaryColorStyles[themeColor] : ''};
  ${({ imageUrl }) =>
    imageUrl &&
    `background-image: url(${imageUrl}); background-size: cover; background-repeat: no-repeat`};
`;
