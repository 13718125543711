import { type VariantProps, cva } from 'class-variance-authority';
import React, { HTMLAttributes, ReactNode } from 'react';

const input = cva(
  [
    'px-3',
    'py-2',
    'focus:outline-none',
    'focus:ring-0',
    'text-grey-1600',
    'w-full',
    'font-primary',
    'disabled:cursor-not-allowed',
    'text-sm',
    'font-normal',
    'border',
    'rounded-sm',
    'transition-colors',
    'ease-in',
    'disabled:bg-grey-200',
    'placeholder:text-grey-1000',
    'placeholder:text-sm',
    'placeholder:font-normal',
  ],
  {
    variants: {
      state: {
        // TODO: Remove this variant since it is not used and is identical to active
        success: [
          'border-grey-800',
          'focus:border-brand-primary',
          'hover:border-brand-primary',
        ],
        active: [
          'border-grey-800',
          'focus:border-brand-primary',
          'hover:border-brand-primary',
        ],
        invalid: ['border-danger'],
      },
    },
    defaultVariants: {
      state: 'active',
    },
  }
);

const text = cva(
  ['font-primary', 'block', 'font-light', 'text-xs', 'font-primary'],
  {
    variants: {
      state: {
        // TODO: Remove this variant since it is not used and is identical to active
        success: ['text-grey-1200'],
        active: ['text-grey-1200'],
        invalid: ['text-danger'],
      },
    },
    defaultVariants: {
      state: 'active',
    },
  }
);

export interface NumberInputProps extends HTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  state?: VariantProps<typeof input>['state'];
  disabled?: boolean;
  required?: boolean;
  value?: number;
  placeholder?: string;
  label?: ReactNode;
  id: string;
  helperText?: string;
  name?: string;
  handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      state = 'active',
      disabled = false,
      required = false,
      value,
      placeholder,
      label,
      id,
      helperText,
      className,
      name,
      handleInputChange,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={`flex flex-col text-left ${className}`}>
        {label && (
          <div className="flex flex-row justify-between">
            <label
              htmlFor={id}
              className="text-grey-1200 font-primary mb-2 font-normal text-xs"
            >
              {label}
            </label>
          </div>
        )}
        <div className="relative">
          <input
            ref={ref}
            className={input({ state })}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            id={id}
            name={name}
            type="number"
            required={required}
            onChange={handleInputChange}
            {...rest}
          />
          {helperText && (
            <div className={`mt-1 ${text({ state })}`}>{helperText}</div>
          )}
        </div>
      </div>
    );
  }
);

NumberInput.displayName = 'NumberInput';
